import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import React, { useState, useEffect } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import AdminService from "../../../components/services/admin.service";
import "../../../assets/styles/admin/datatable.css";
import envelope from "../../../assets/images/successfulcards/envelope-fill.svg";
import updateEnvelope from "../../../assets/images/successfulcards/refund-icon.png";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { Calendar } from 'primereact/calendar';
import moment from "moment";
import { lStorage } from "../../../components/utils/storage";

const AdminDataTable = () => {
  const [customers, setCustomers] = useState();
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    amount: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    pnr_no: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    invoice_name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    invoice_emailid: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    payment_status: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    payment_failed_reason: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    payment_date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [emailData, setEmailData] = useState();

  const adminDetails = lStorage.get("adminInfo");
  const getHref = () => {
    if (adminDetails && adminDetails?.super_admin == 1) {
      return "/admin/racelocations";
    } else {
      return "/admin/addparticipant";
    }
  };

  const adminServices = new AdminService();

  useEffect(() => {
    const pageSizeDetails = {
      lazyEvent: { first: 0, rows: 7000, page: 1, filters: {} },
    };
    adminServices.getorderparticipantlist(pageSizeDetails).then((data) => {
      setCustomers(data?.data?.data);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
        <span >
        <a href={getHref()} class="btn btn-primary">Add Participants</a>
        </span>
      </div>
    );
  };

  const orderListCall = () =>{
    const pageSizeDetails = {
      lazyEvent: { first: 0, rows: 5000, page: 1, filters: {} },
    };
    adminServices.getorderparticipantlist(pageSizeDetails).then((data) => {
      setCustomers(data?.data?.data);
    });
  }

  const actionButton = (d) => {
    setOpenModal(false);
    setLoading(true);
    const pnrNumber = {
      pnr_no: d?.pnr_no,
    };
    if (d.payment_status === "ORDER_CONFIRMED") {
      adminServices.resendmail(pnrNumber).then((res) => {
        if (res?.data?.isError === false && res.status === 200) {
          setLoading(false);
          toast.success(res?.data?.message);
          orderListCall();
        } else {
          setLoading(false);
          toast.error("Something went wrong please try again later");
        }
      }).catch(function (error) {
        // console.log(error);
      });
    } else if (
      d.payment_status === "ORDER_FAILED"
    ) {
      adminServices.paymentstatusupdate(pnrNumber).then((res) => {
        if (res?.data?.isError === false && res.status === 200) {
          setLoading(false);
          toast.success(res?.data?.message);
          orderListCall();
        } else {
          setLoading(false);
          toast.error("Something went wrong please try again later");
        }
      }).catch(function (error) {
        // console.log(error);
      });
    }
  };

  const confirmationPopup = (data) =>{
    setEmailData(data)
    setOpenModal(true);
  }

  const buttonTemplate = (data) => (
    <>
      <span
        type="button"
        role="button"
        tabIndex="0"
        onKeyDown={(e) => confirmationPopup(data)}
        onClick={(e) => confirmationPopup(data)}
        title={
          data?.payment_status === "ORDER_CONFIRMED"
            ? "Resend Email"
            : "Refund Mail"
        }
      >
        {data?.payment_status === "ORDER_CONFIRMED" ? (
          <img className="img-fluid" src={envelope} alt="Clock logo" />
        ) : data?.payment_status === "ORDER_FAILED" && (
          <img className="img-fluid" src={updateEnvelope} alt="Clock logo" style={{width:"25px"}}/>
        )}
      </span>
    </>
  );


const dateFilterTemplate = (options) => {
  return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd-mm-yy" placeholder="dd-mm-yyyy" mask="99-99-9999" />
}

const dateBodyTemplate = (rowData) => {
  if (rowData?.payment_date !== null) {
    return moment(rowData?.payment_date).format("DD-MM-YYYY hh:mm A");
  }
}

const reasonTemplate = (rowData) => {
  if(rowData?.payment_status === "ORDER_FAILED"){
    return rowData?.payment_failed_reason;
  }
}

  const header = renderHeader();

  return (
    <div className="datatable-doc-demo">
      <div className="card">
        <DataTable
          value={customers}
          paginator
          className="p-datatable-customers"
          header={header}
          rows={15}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[15, 30, 50]}
          dataKey="id"
          rowHover
          selection={selectedCustomers}
          onSelectionChange={(e) => setSelectedCustomers(e.value)}
          filters={filters}
          filterDisplay="menu"
          loading={loading}
          responsiveLayout="scroll"
          stripedRows
          size="small"
          emptyMessage="No Records found."
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          <Column
            field="invoice_name"
            header="Name"
            sortable
            style={{ width: "5rem" }}
            filter
            filterPlaceholder="Search by Name"
          />
          <Column
            field="invoice_emailid"
            header="Email"
            sortable
            style={{ width: "5rem" }}
            filter
            filterPlaceholder="Search by Email"
          />
          <Column
            field="pnr_no"
            header="PNR"
            sortable
            style={{ width: "10rem" }}
            filter
            filterPlaceholder="Search by PNR Number"
          />

          <Column
            field="amount"
            header="Amount"
            sortable
            filter
            style={{ width: "4rem" }}
          />
          <Column
            field="payment_status"
            header="Payment Status"
            sortable
            style={{ width: "5rem" }}
            filter
          />
          <Column
            field="payment_date"
            header="Date"
            sortable
            filterField="payment_date"
            dataType="date"
            style={{ minWidth: "8rem" }}
            body={dateBodyTemplate}
            filter
            filterElement={dateFilterTemplate}
          />
          <Column
            field="payment_failed_reason"
            header="Payment Failed Reason"
            sortable
            style={{ minWidth: "10rem" }}
            filter
            body={reasonTemplate}
          />
          <Column
            header="Actions"
            headerStyle={{ width: "4rem", textAlign: "center" }}
            bodyStyle={{ textAlign: "center", overflow: "visible" }}
            body={buttonTemplate}
          />
        </DataTable>
      </div>
      <ToastContainer position="top-right" autoClose={3000} />
      <Modal
        show={openModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="register-popup"
      >
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>
              {emailData?.payment_status === "ORDER_CONFIRMED"
                ? "Resend Email"
                : "Refund Mail"}
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            {emailData?.payment_status === "ORDER_CONFIRMED"
              ? "Are you sure want to resend email ?"
              : "Are you sure want to send refund mail ?"}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="whiteColor" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => actionButton(emailData)}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminDataTable;
